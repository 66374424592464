import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right.svg";

const DiscoverSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="discover-section container_bx">
      <div className="discover-wrapper border border-[#FFFFFF33] rounded-[20px] bg-[#071C3C] py-[45px] px-[20px] md:px-[100px] text-center relative overflow-hidden">
        <div className="overlay-image"></div>
        <div className="block relative z-[999]">
          <h1 className="text-3xl  md:text-5xl font-bold">{t("start-your")}</h1>
          <span className="digital-text text-3xl md:text-5xl font-bold text-[#2ED2FF]">
            {t(`digital-trans-journey`)}
          </span>
          <p className="text-3xl  md:text-5xl font-bold">{t(`with-us`)}</p>
          <div className="mt-[52px]">
            <div className="flex items-center justify-center">
              <button
                className="py-3 px-6 bg-[#09AAD6] rounded-full m-auto text-[24px] flex items-center justify-start gap-[10px] "
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/contact");
                }}
              >
                {t(`contact-us`)}
                <span
                  className={`w-[36px] h-[36px] flex justify-center items-center bg-white rounded-full ${
                    t(`lang`) === "ar" ? "rotate-180" : ""
                  }`}
                >
                  <ArrowRight />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscoverSection;
