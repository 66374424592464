import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as FacebookSVG } from "../../assets/images/footer-facebook.svg";
import { ReactComponent as InstagramSVG } from "../../assets/images/footer-insta.svg";
import { ReactComponent as LinkedInSVG } from "../../assets/images/footer-linkedin.svg";
import { ReactComponent as XSVG } from "../../assets/images/footer-x.svg";
import logoImg from "../../assets/images/white-logo.svg";
import Slider from "./Slider";

const Footer = () => {
  let date = new Date();
  let year = date.getFullYear() || "2024";

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const socialMedia = [
    {
      key: 1,
      title: "facebook",
      icon: <FacebookSVG />,
      link: `https://www.facebook.com/wfdEgypt`,
    },
    {
      key: 2,
      title: "instagram",
      icon: <InstagramSVG />,
      link: `https://www.instagram.com/wfdEgypt`,
    },
    {
      key: 3,
      title: "x",
      icon: <XSVG />,
      link: `https://twitter.com/wfdEgypt`,
    },
    {
      key: 4,
      title: "linkedIn",
      icon: <LinkedInSVG />,
      link: `https://www.linkedin.com/company/wfdegypt/`,
    },
  ];

  function hideSlider() {
    if (
      pathname.includes("products") ||
      pathname.includes("services") ||
      pathname.includes("terms-and-conditions") ||
      pathname.includes("blog")
    ) {
      return true;
    }
  }

  return (
    <div className="footer-section container_bx" data-aos="fade-up">
      {hideSlider() ? null : <Slider />}

      <div className="footer-items">
        <Row gutter={{ xs: 20, sm: 20, md: 40, lg: 40 }}>
          <Col className="gutter-row" xs={24} lg={12}>
            <div className="item">
              <img src={logoImg} alt="pic" />
              <p>{t("footer-text")}</p>
              <div className="social-wrapper flex gap-[10px] items-center justify-start">
                {socialMedia.map((item) => {
                  return (
                    <span
                      key={item.key}
                      className="inline-block w-[32px] h-[32px] cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(item.link, "_blank", "noopener,noreferrer");
                      }}
                    >
                      {item.icon}
                    </span>
                  );
                })}
              </div>
            </div>
          </Col>

          <Col className="gutter-row" xs={24} lg={6}>
            <div className="item">
              <h3>{t("company")}</h3>
              <ul>
                <li>
                  <p
                    onClick={() => {
                      window.scroll({ top: 0, behavior: "smooth" });
                      navigate("/services/all");
                    }}
                    to="/service"
                  >
                    {t("services")}
                  </p>
                </li>
                <li>
                  <p
                    onClick={() => {
                      window.scroll({ top: 0, behavior: "smooth" });
                      navigate("/products/all");
                    }}
                    to="/products/all"
                  >
                    {t("products")}
                  </p>
                </li>
                <li>
                  <p
                    onClick={() => {
                      window.scroll({ top: 0, behavior: "smooth" });
                      navigate("/about");
                    }}
                    to="/about"
                  >
                    {t("about-us")}
                  </p>
                </li>
              </ul>
            </div>
          </Col>

          <Col className="gutter-row" xs={24} lg={6}>
            <div className="item">
              <h3>{t("help")}</h3>
              <ul>
                <li>
                  <p
                    onClick={() => {
                      window.scroll({ top: 0, behavior: "smooth" });
                      navigate("/contact");
                    }}
                    to="/contact"
                  >
                    {t("contact-us")}
                  </p>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
