import i18n from "i18next";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppRouter from "./AppRouter";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import { checkIconOnLoad } from "./helpers/handelFavicon.js";
// import AnimatedCursor from 'react-animated-cursor';
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
    checkIconOnLoad();
  }, [pathname]);
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
    return () => {
      AOS.refresh();
    };
  }, []);

  document.documentElement.lang = i18n.language;

  function isHome() {
    if (pathname === "/") {
      return true;
    }
  }

  return (
    <div className={`main-bx ${isHome() ? "" : "main-bx-top"}`}>
      <div className="main-header-image absolute top-0 left-0 right-0 bottom-0 mix-blend-plus-lighter opacity-30">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1435"
          height="1072"
          viewBox="0 0 1435 1072"
          fill="none"
        >
          <g
            style={{ mixBlendMode: "plus-lighter" }}
            opacity="0.8"
            filter="url(#filter0_f_74_1296)"
          >
            <path
              d="M176.977 527.088C607.453 367.681 260.736 127.547 549.047 20.7843C837.358 -85.9785 1111.14 -110.785 1224.58 195.552C1338.02 501.89 1147.43 751.374 859.122 858.137C570.811 964.899 290.415 833.426 176.977 527.088Z"
              fill="url(#paint0_linear_74_1296)"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_74_1296"
              x="0.277054"
              y="-228.751"
              width="1434.23"
              height="1300.22"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="88.35"
                result="effect1_foregroundBlur_74_1296"
              />
            </filter>
            <linearGradient
              id="paint0_linear_74_1296"
              x1="907.942"
              y1="943.536"
              x2="497.147"
              y2="-165.809"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#09AAD6" />
              <stop offset="1" stopColor="#020202" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="mouse-cursor">
        {/* <AnimatedCursor
                    innerSize={18}
                    outerSize={18}
                    color={'128, 224, 251'}
                    outerAlpha={0.2}
                    innerScale={0.7}
                    outerScale={5}
                /> */}
      </div>
      <Header />
      <div className="mt-[100px]">
        <AppRouter />
      </div>
      <Footer />
    </div>
  );
};

export default App;
