import React from "react";
import { useTranslation } from "react-i18next";
import imploy1 from "../../assets/images/products/imploy/1.png";
import imploy2 from "../../assets/images/products/imploy/2.png";
import imploy3 from "../../assets/images/products/imploy/3.png";
import ContentDetails from "../../components/ContentDetails/ContentDetails";

const Imploy = () => {
  const { t, i18n } = useTranslation();

  const contentData = [
    {
      image: imploy1,
      title: i18n.language === "ar" ? t(`about-imploy`) : t(`about`),
      details: [
        {
          content1: t(`imploy-p1-1`),
        },
        {
          content1: t("imploy-p1-2"),
        },
      ],
    },
    {
      image: imploy2,
      title: i18n.language === "ar" ? t(`imploy-p3-title1`) : t(`imploy-h1-2`),
      details: [
        {
          content: t("imploy-p2-1"),
        },
        {
          content: t("imploy-p2-2"),
        },
        {
          content: t("imploy-p2-4"),
        },
        {
          content: t("imploy-p2-5"),
        },
      ],
    },
    {
      image: imploy3,
      title: i18n.language === "ar" ? t(`imploy-p3-title2`) : t(`solutions`),
      details: [
        {
          content: t("imploy-p3-2"),
        },
        {
          content: t("imploy-p3-4"),
        },
        {
          content: t("imploy-p3-5"),
        },

        {
          content: t("imploy-p3-7"),
        },
      ],
    },
  ];

  return (
    <div data-aos="fade-up">
      <div className="title-text w-75 mx-auto my-50 text-center">
        <h1>{t("Imploy")}</h1>
        {/* <p>{t(`imploy-page-title`)}</p> */}
      </div>

      <div className="product-details-text service-details-wrapper md:!mt-[250px]">
        <div className="flex flex-col gap-[100px]">
          {contentData.map((content, index) => (
            <ContentDetails key={index} contentData={content} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Imploy;
