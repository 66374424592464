import Axios from "./Axios";
import { clientId, secretId } from "./baseURL";

export const contactForm = (data = {}) => {
  return Axios.post("website/lead/form", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getAuth1 = () => {
  return Axios.get(
    `common/oauth2/authorize?client_id=${clientId}&response_type=code`
  );
};

export const createLead = async (data = {}) => {
  let accessToken = "";
  const response = await Axios.get(
    `common/oauth2/authorize?client_id=${clientId}&response_type=code`
  );
  if (response.data.code) {
    const AccessResponse = await Axios.get(
      `common/oauth2/access_token?client_id=${clientId}&client_secret=${secretId}&code=${response.data.code}`
    );
    accessToken = AccessResponse.data.access_token;
  }
  return Axios.post(
    `object/crm.lead?vals=${JSON.stringify(data)}`,
    {},
    {
      headers: {
        "Content-Type": "",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};
