import React from "react";
import { useTranslation } from "react-i18next";
import wfi1 from "../../assets/images/products/wfi/1.png";
import wfi2 from "../../assets/images/products/wfi/2.png";
import wfi3 from "../../assets/images/products/wfi/3.png";
import ContentDetails from "../../components/ContentDetails/ContentDetails";

const Wfi = () => {
  const { t, i18n } = useTranslation();

  const contentData = [
    {
      image: wfi1,
      title: i18n.language === "ar" ? t(`about-wfi`) : t(`about`),
      details: [
        {
          content1: t("wfi-p1-1"),
        },
        {
          content1: t("wfi-p1-2"),
        },
        {
          content1: t("wfi-p1-3"),
        },
        {
          content1: t("wfi-p1-4"),
        },
      ],
    },
    {
      image: wfi2,
      title: i18n.language === "ar" ? t(`wfi-h1`) : t(`customer-needs`),
      details:
        i18n.language === "ar"
          ? [
              {
                content: t("wfi-p2-1"),
              },
              {
                content: t("wfi-p2-2"),
              },
              {
                content: t("wfi-p2-3"),
              },
            ]
          : [
              {
                content: t("wfi-p2-1"),
              },
              {
                content: t("wfi-p2-2"),
              },
              {
                content: t("wfi-p2-3"),
              },
              {
                content: t("wfi-p2-4"),
              },
            ],
    },
    {
      image: wfi3,
      title: i18n.language === "ar" ? t(`wfi-h2`) : t(`solutions`),
      details:
        i18n.language === "ar"
          ? [
              {
                content: t("wfi-p3-1"),
              },
              {
                content: t("wfi-p3-2"),
              },
            ]
          : [
              {
                content: t("wfi-p3-1"),
              },
              {
                content: t("wfi-p3-2"),
              },
              {
                content: t("wfi-p3-3"),
              },
              {
                content: t("wfi-p3-4"),
              },
            ],
    },
  ];

  return (
    <div data-aos="fade-up">
      <div className="title-text w-75 mx-auto my-50 text-center">
        <h1 className="uppercase">{t("WFI")}</h1>
      </div>

      <div className="product-details-text service-details-wrapper !mt-[100px]">
        <div className="flex flex-col gap-[100px]">
          {contentData.map((content, index) => (
            <ContentDetails key={index} contentData={content} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Wfi;
