import React from "react";
import { useTranslation } from "react-i18next";
import "../../assets/css/pages/services.css";
import platformImg from "../../assets/images/services/cloud-services/1.svg";
import sassImg from "../../assets/images/services/cloud-services/2.svg";
import ContentDetails from "../../components/ContentDetails/ContentDetails";

const CloudServices = () => {
  const { t } = useTranslation();

  const contentData = [
    {
      image: sassImg,
      details: [
        {
          title: t(`sass-title-h1`),
        },
        {
          content1: t("sass-text-p"),
        },
      ],
    },
    {
      image: platformImg,
      details: [
        {
          title: t("platform-title-h1"),
        },
        {
          content1: t("platform-text-p"),
        },
      ],
    },
  ];
  return (
    <>
      <div className="data-text-bx container_bx">
        <div className="title-text w-75 mx-auto text-center">
          <h1>{t("cloud-services")}</h1>
          {/* <p>{t("cloud-title-p")}</p> */}
        </div>

        <div className="product-details-text service-details-wrapper md:!mt-[200px]">
          <div className="flex flex-col gap-[100px]">
            {contentData.map((content, index) => (
              <ContentDetails key={index} contentData={content} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CloudServices;
