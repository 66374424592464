import React from "react";

import { Col, Row } from "antd";

import { useTranslation } from "react-i18next";

import about1 from "../../assets/images/about/about-1.svg";
import about2 from "../../assets/images/about/about-2.svg";

const WhySection = () => {
  const { t } = useTranslation();

  return (
    <div className="why-text-section container_bx">
      <div>
        <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }} align={"flex-start"}>
          <Col className="gutter-row" xs={24} lg={24} xl={6}>
            <div className="who-text-img">
              <img src={about1} alt="pic" />
            </div>
          </Col>

          <Col className="gutter-row" xs={24} lg={24} xl={12}>
            <div className="what-we-do-section container_bx">
              <div className="text text-center" style={{ width: "100%" }}>
                <span className="text-[20px]">{t("different-sec")}</span>
                <h1 className="max-w-[814px] m-auto">
                  {t("different-sec-h1")}
                </h1>
              </div>

              <div className="label-wrapper text-center">
                <p className="text-lg">{t(`different-sec-p`)}</p>
                <p className="text-lg mt-[20px]">{t(`different-sec-p2`)}</p>
              </div>
            </div>
          </Col>

          <Col className="gutter-row" xs={24} lg={24} xl={6}>
            <div className="who-text-img">
              <img src={about2} alt="pic" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WhySection;
