import React from 'react'
import { ReactComponent as Empty } from '../../assets/images/empty.svg';
import "./NoData.css"

export default function NoData() {
    return (
        <div className="empty-page container_bx">
            <Empty />
            <h1>No Data</h1>
        </div>
    )
}
