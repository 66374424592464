import React from "react";

import "../../assets/css/pages/about-us.css";

import PageBanner from "./PageBanner";
import TeamSection from "./TeamSection";
import WhatWeDoSection from "./WhatWeDoSection";
import WhoText from "./WhoText";
import WhySection from "./WhySection";
import DiscoverSection from "../home/DiscoverSection";

const About = () => {
  return (
    <>
      <div data-aos="fade-up">
        <PageBanner />
      </div>

      <div data-aos="fade-up">
        <WhoText />
      </div>

      <div data-aos="fade-up">
        <WhatWeDoSection />
      </div>

      <div data-aos="fade-up">
        <WhySection />
      </div>

      <div data-aos="fade-up" className="!hidden">
        <TeamSection />
      </div>

      <div data-aos="fade-up">
        <DiscoverSection />
      </div>
    </>
  );
};

export default About;
