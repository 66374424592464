import React from "react";
import { useTranslation } from "react-i18next";
import shaari1 from "../../assets/images/products/shaari/1.png";
import shaari2 from "../../assets/images/products/shaari/2.png";
import shaari3 from "../../assets/images/products/shaari/3.png";
import ContentDetails from "../../components/ContentDetails/ContentDetails";

const Shaari = () => {
  const { t, i18n } = useTranslation();

  const contentData = [
    {
      image: shaari1,
      title: i18n.language === "ar" ? t(`about-shaari`) : t(`about`),
      details: [
        {
          content1: t(`shaari-p-1`),
        },
        {
          content1: t("shaari-p-2"),
        },
      ],
    },
    {
      image: shaari2,
      title: i18n.language === "ar" ? t(`shaari-h2`) : t(`customer-needs`),
      details:
        i18n.language === "ar"
          ? [
              {
                content1: t("card1-p"),
              },
            ]
          : [
              {
                content: t("card1-p"),
              },

              {
                content: t("card2-p"),
              },

              {
                content: t("card3-p"),
              },

              {
                content: t("card4-p"),
              },
            ],
    },
    {
      image: shaari3,
      title: i18n.language === "ar" ? t(`shaari-h3`) : t(`solutions`),
      details:
        i18n.language === "ar"
          ? [
              {
                content: t("shaari-p-5"),
              },
              {
                content: t("shaari-p-6"),
              },
              {
                content: t("shaari-p-7"),
              },
            ]
          : [
              {
                content: t("shaari-p-5"),
              },
              {
                content: t("shaari-p-6"),
              },
              {
                content: t("shaari-p-7"),
              },
              {
                content: t("shaari-p-8"),
              },
            ],
    },
  ];

  return (
    <div data-aos="fade-up">
      <div className="title-text w-75 mx-auto my-50 text-center">
        <h1>{t("Shaari")}</h1>
        {/* <p>{t(`shaari-p-1`)}</p> */}
      </div>

      <div className="product-details-text service-details-wrapper md:!mt-[250px]">
        <div className="flex flex-col gap-[100px]">
          {contentData.map((content, index) => (
            <ContentDetails key={index} contentData={content} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Shaari;
