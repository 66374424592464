export function checkIconOnLoad() {
    let favicon = document.querySelector("link[rel*='shortcut icon']");

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && favicon) {
        favicon.href = '/light-favicon.svg';
    }

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches && favicon) {
        favicon.href = '/favicon.svg';
    }
}
