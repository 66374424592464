import React from "react";

function TermsAndConditios() {
  return (
    <div className="mt-16 md:mx-14">
      <h1 className="!text-center text-[52px] text-white font-bold">
        Terms And Conditions
      </h1>
      <div className="container mt-[120px] w-3/4">
        <h2 className="text-white font-semibold text-3xl">
          Digital Office App Terms and Conditions
        </h2>
        <div>
          <h2 className="text-white font-medium text-2xl mt-5">
            1. Introduction
          </h2>
          <p className="text-white font-normal text-lg mt-5 ">
            Welcome to Digital Office, a mobile application developed by Watan
            First Digital Company. By downloading or using the app, these terms
            will automatically apply to you – you should make sure therefore
            that you read them carefully before using the app.
          </p>
        </div>
        <div>
          <h2 className="text-white font-medium text-2xl mt-5">
            2. Definitions
          </h2>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-lg mt-5 whitespace-nowrap">
              App
            </p>
            <p> : Digital Office</p>
          </div>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-lg mt-5 whitespace-nowrap">
              Company
            </p>
            <p> : Watan First Digital Company</p>
          </div>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-lg mt-5 whitespace-nowrap">
              User/Employee
            </p>
            <p> : Any individual who downloads, installs, or uses the App</p>
          </div>
        </div>
        <div>
          <h2 className="text-white font-medium text-2xl mt-5">
            3. Acceptance of Terms
          </h2>
          <p className="text-white font-normal text-lg mt-5">
            By using the App, you agree to be bound by these terms and
            conditions. If you do not agree with these terms, please do not use
            the App.
          </p>
        </div>
        <div>
          <h2 className="text-white font-medium text-2xl mt-5">
            4. Use of the App
          </h2>
          <p className="text-white font-normal text-lg mt-5 ">
            The App is designed to facilitate access to the Company’s ERP
            system, enabling employees to perform remote work, follow tasks,
            punch in and out via QR code scanning, engage in instant messaging,
            and submit time-off requests.
          </p>
        </div>
        <div>
          <h2 className="text-white font-medium text-2xl mt-5">
            5. User Responsibilities
          </h2>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-lg mt-5 whitespace-nowrap">
              Account Information
            </p>
            <p>
              {" "}
              : Users must ensure that the information provided during
              registration is accurate and up-to-date.
            </p>
          </div>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-lg mt-5 whitespace-nowrap">
              Compliance
            </p>
            <p>
              {" "}
              : Users agree to comply with all applicable laws and regulations
              while using the App.
            </p>
          </div>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-lg mt-5 whitespace-nowrap">
              Security
            </p>
            <p>
              {" "}
              : Users are responsible for maintaining the confidentiality of
              their account credentials.
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-white font-medium text-2xl mt-5">6.Privacy : </h2>
          <p className="text-white font-normal text-lg mt-5 ">
            At Digital office, protecting your privacy and ensuring the
            protection of your data is of utmost importance to us. This section
            outlines our commitment to transparent data practices, compliance
            with applicable data protection laws, and the measures we employ to
            collect, use, and protect user information.
          </p>
        </div>
        <div>
          <h2 className="text-white font-medium text-2xl mt-5">
            7.Data Protection :
          </h2>
          <p className="text-white font-normal text-lg mt-5 ">
            We employ industry-standard security measures to protect your data
            from unauthorized access, alteration, disclosure, or destruction.
            These measures encompass encryption protocols, secure access
            controls, and regular security assessments. Our specialized team
            diligently monitors for potential vulnerabilities and promptly
            addresses any identified issues.
          </p>
        </div>
        <div>
          <h2 className="text-white font-medium text-2xl mt-5">
            8.Intellectual Property :
          </h2>
          <p className="text-white font-normal text-lg mt-5 ">
            All intellectual property rights in the App and its content are
            owned by or licensed to the Company. Users are granted a limited,
            non-exclusive, non-transferable license to use the App solely for
            the intended purposes.
          </p>
        </div>
        <div>
          <h2 className="text-white font-medium text-2xl mt-5">
            9.Modifications to the App :
          </h2>
          <p className="text-white font-normal text-lg mt-5 ">
            The Company reserves the right to modify, suspend, or discontinue
            the App or any part of it at any time without notice. The Company
            shall not be liable to Users or any third party for any
            modification, suspension, or discontinuation of the App.
          </p>
        </div>
        <div>
          <h2 className="text-white font-medium text-2xl mt-5">
            10. Termination and Suspension
          </h2>
          <p className="mt-3">
            WFD reserves the right to terminate or suspend user accounts under
            the following conditions:
          </p>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-xl mt-2 whitespace-nowrap">
              Breach of Terms
            </p>
            <p>
              {" "}
              : If a user violates any of the terms outlined in this agreement
            </p>
          </div>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-xl mt-2 whitespace-nowrap">
              False Information
            </p>
            <p>
              {" "}
              : Providing false or misleading information in user profiles or
              job postings.
            </p>
          </div>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-xl mt-2 whitespace-nowrap">
              Illegal Activities
            </p>
            <p> : Engaging in illegal activities on the platform.</p>
          </div>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-xl mt-2 whitespace-nowrap">
              Harassment or Misconduct
            </p>
            <p>
              {" "}
              : Engaging in harassment, bullying, or any form of inappropriate
              conduct towards other users.
            </p>
          </div>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-xl mt-2 whitespace-nowrap">
              Spam or Misuse
            </p>
            <p>
              {" "}
              : Sending unsolicited messages or spamming or misusing platform
              features.
            </p>
          </div>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-xl mt-2 whitespace-nowrap">
              Security Concerns
            </p>
            <p>
              {" "}
              : If there are concerns about the security of a user’s account or
              any suspicious activities.
            </p>
          </div>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-xl mt-2 whitespace-nowrap">
              Non-payment
            </p>
            <p> : Failure to pay for premium services (if any).</p>
          </div>
          <div className="flex items-baseline">
            <p className="text-white font-normal text-xl mt-2 whitespace-nowrap">
              Non-compliance
            </p>
            <p>
              {" "}
              : Non-compliance with any additional guidelines or policies
              provided by Digital office.
            </p>
          </div>
          <p className="mt-3">
            {" "}
            Account Deletion: Users may request the deletion of their account at
            any time. Upon receiving a deletion request, we will deactivate the
            account and remove most of the data associated with the account.
          </p>
          <p className="mt-3">
            {" "}
            Data Retention: While the majority of the user's data will be
            removed, certain information may be retained for legal or regulatory
            purposes, such as to comply with financial record-keeping
            requirements or to resolve disputes. This retained data will be
            anonymized and will not include any personally identifiable
            information, except where required by law.
          </p>
          <p className="mt-3">
            {" "}
            The termination or suspension of a user’s account may be temporary
            or permanent, depending on the severity and recurrence of the
            violation. Digital office will notify and explain such actions to
            users whenever possible. Users have the right to appeal decisions
            related to account termination or suspension.
          </p>
        </div>
        <div>
          <h2 className="text-white font-medium text-2xl mt-5">
            11.Limitation of Liability :
          </h2>
          <p className="text-white font-normal text-lg mt-5 ">
            The App is provided "as is" and "as available" without any
            warranties of any kind, either express or implied. The Company does
            not warrant that the App will be uninterrupted or error-free. In no
            event shall the Company be liable for any damages arising out of or
            in connection with the use or inability to use the App.
          </p>
        </div>
        <div>
          <h2 className="text-white font-medium text-2xl mt-5">
            12.Contact Information :
          </h2>
          <p className="text-white font-normal text-lg mt-5 whitespace-nowrap">
            For any questions about these Terms and Conditions, please contact
            us at:
          </p>
        </div>
        <div className="flex items-baseline">
          <p className="text-white font-normal text-lg mt-5 whitespace-nowrap">
            Email
          </p>
          <p> : Email: info@watanfd.com</p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditios;
