import React, { useState } from "react";

import "../../assets/css/pages/contact-us.css";
import Spinner from "../../components/Spinner/Spinner";
import ContactSection from "./ContactSection";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading && <Spinner />}
      <div data-aos="fade-up">
        <ContactSection loading={loading} setLoading={setLoading} />
      </div>
    </>
  );
};

export default Contact;
