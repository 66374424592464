import React from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from "../../assets/images/companies/1.png";
import img10 from "../../assets/images/companies/10.png";
import img11 from "../../assets/images/companies/11.png";
import img12 from "../../assets/images/companies/12.png";
import img13 from "../../assets/images/companies/13.png";
import img14 from "../../assets/images/companies/14.png";
import img15 from "../../assets/images/companies/15.png";
import img16 from "../../assets/images/companies/16.png";
import img17 from "../../assets/images/companies/17.png";
import img18 from "../../assets/images/companies/18.png";
import img19 from "../../assets/images/companies/19.png";
import img2 from "../../assets/images/companies/2.png";
import img20 from "../../assets/images/companies/20.png";
import img21 from "../../assets/images/companies/21.png";
import img22 from "../../assets/images/companies/22.png";
import img23 from "../../assets/images/companies/23.png";
import img24 from "../../assets/images/companies/24.png";
import img25 from "../../assets/images/companies/25.png";
import img26 from "../../assets/images/companies/26.png";
import img27 from "../../assets/images/companies/27.png";
import img28 from "../../assets/images/companies/28.png";
import img29 from "../../assets/images/companies/29.png";
import img3 from "../../assets/images/companies/3.png";
import img30 from "../../assets/images/companies/30.png";
import img31 from "../../assets/images/companies/31.png";
import img32 from "../../assets/images/companies/32.png";
import img33 from "../../assets/images/companies/33.png";
import img34 from "../../assets/images/companies/34.png";
import img35 from "../../assets/images/companies/35.png";
import img36 from "../../assets/images/companies/36.png";
import img37 from "../../assets/images/companies/37.png";
import img38 from "../../assets/images/companies/38.png";
import img39 from "../../assets/images/companies/39.png";
import img4 from "../../assets/images/companies/4.png";
import img40 from "../../assets/images/companies/40.png";
import img41 from "../../assets/images/companies/41.png";
import img42 from "../../assets/images/companies/42.png";
import img43 from "../../assets/images/companies/43.png";
import img44 from "../../assets/images/companies/44.png";
import img45 from "../../assets/images/companies/45.png";
import img46 from "../../assets/images/companies/46.png";
import img47 from "../../assets/images/companies/47.png";
import img48 from "../../assets/images/companies/48.png";
import img49 from "../../assets/images/companies/49.png";
import img5 from "../../assets/images/companies/5.png";
import img50 from "../../assets/images/companies/50.png";
import img51 from "../../assets/images/companies/51.png";
import img52 from "../../assets/images/companies/52.png";
import img53 from "../../assets/images/companies/53.png";
import img54 from "../../assets/images/companies/54.png";
import img55 from "../../assets/images/companies/55.png";
import img56 from "../../assets/images/companies/56.png";
import img57 from "../../assets/images/companies/57.png";
import img58 from "../../assets/images/companies/58.png";
import img59 from "../../assets/images/companies/59.png";
import img6 from "../../assets/images/companies/6.png";
import img60 from "../../assets/images/companies/60.png";
import img61 from "../../assets/images/companies/61.png";
import img62 from "../../assets/images/companies/62.png";
import img63 from "../../assets/images/companies/63.png";
import img64 from "../../assets/images/companies/64.png";
import img65 from "../../assets/images/companies/65.png";
import img66 from "../../assets/images/companies/66.png";
import img67 from "../../assets/images/companies/67.png";
import img68 from "../../assets/images/companies/68.png";
import img69 from "../../assets/images/companies/69.png";
import img7 from "../../assets/images/companies/7.png";
import img70 from "../../assets/images/companies/70.png";
import img71 from "../../assets/images/companies/71.png";
import img72 from "../../assets/images/companies/72.png";
import img73 from "../../assets/images/companies/73.png";
import img74 from "../../assets/images/companies/74.png";
import img75 from "../../assets/images/companies/75.png";
import img76 from "../../assets/images/companies/76.png";
import img8 from "../../assets/images/companies/8.png";
import img9 from "../../assets/images/companies/9.png";
import "./Slider.css";

const Slider = () => {
  const { t } = useTranslation();

  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39,
    img40,
    img41,
    img42,
    img43,
    img44,
    img45,
    img46,
    img47,
    img48,
    img49,
    img50,
    img51,
    img52,
    img53,
    img54,
    img55,
    img56,
    img57,
    img58,
    img59,
    img60,
    img61,
    img62,
    img63,
    img64,
    img65,
    img66,
    img67,
    img68,
    img69,
    img70,
    img71,
    img72,
    img73,
    img74,
    img75,
    img76,
  ];

  // carousel
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
      partialVisibilityGutter: 20,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 8,
      partialVisibilityGutter: 20,
    },
    desktop2: {
      breakpoint: { max: 1200, min: 1024 },
      items: 4,
      partialVisibilityGutter: 20,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2.5,
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2.5,
    },
  };

  return (
    <>
      <div className="companie-section container_bx">
        <div className="text">
          <h1 className="text-2xl">{t(`meet-partners`)}</h1>
        </div>
      </div>
      <div className="companies-slider">
        <Carousel responsive={responsive} infinite={true} arrows={false} autoPlay={true} autoPlaySpeed={2500} pauseOnHover={true} >
          {images.map((image, index) => (
            <div className="carousel-item" key={index} style={{ cursor: "grab" }}>
              <div className="company-img">
                <img src={image} alt="company" />
              </div>
            </div>
          ))}
        </Carousel>
      </div >
    </>
  );
};

export default Slider;
