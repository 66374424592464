import { Col, Row } from "antd";
import React from "react";
import CountUp from "react-countup";

import { useTranslation } from "react-i18next";

const WelcomeSection = () => {
  const { t } = useTranslation();

  return (
    <div className="welcome-section container_bx mt-[140px]" id="about">
      <div className="welcome-bx">
        <Row
          gutter={{
            xs: 20,
            sm: 20,
            md: 20,
            lg: 20,
          }}
        >
          <Col className="gutter-row" sm={24} md={24} xl={24}>
            <div className="text text-center">
              {/* <div className='small-title'>{t('welcome')}</div> */}
              <span className="font-normal text-[40px]">
                {t("dreaming-big")}
                <h2 className="mx-2 inline-block !text-center font-bold">
                  {t(`achieving-bigger`)}
                </h2>
              </span>
              {/* <p>{t("welcome-p")}</p> */}
            </div>
          </Col>

          <Col className="gutter-row" sm={24} md={24} xl={24}>
            <div className="counter-bx grid grid-cols-2 lg:grid-cols-4 mt-[30px]">
              <div className="item">
                <span>
                  <span className="!m-0">+</span>
                  <CountUp end={7} delay={1} />
                </span>
                <p>{t("digital-products")}</p>
              </div>

              <div className="item">
                <span>
                  <span className="!m-0">+</span>
                  <CountUp end={9} delay={1} />
                </span>
                <p>{t("digital-services")}</p>
              </div>

              <div className="item">
                <span>
                  <span className="!m-0">+</span>
                  <CountUp end={60} delay={1} />
                </span>
                <p>{t("active-clients")}</p>
              </div>

              <div className="item">
                <span>
                  <span className="!m-0">+</span>
                  <CountUp end={80} delay={1} />
                </span>
                <p>{t("team-members")}</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WelcomeSection;
