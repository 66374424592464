import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assets/images/products/arrow.svg";

export default function ProductCard({ productData = {} }) {
  const navigate = useNavigate();

  return (
    <div className="product-card-wrapper">
      <div className="product-card">
        <div
          className="image-wrapper rounded-[12px] cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            navigate(productData.route);
          }}
        >
          <img
            src={productData.image}
            alt="pic"
            className="w-full h-full rounded-xl"
          />
        </div>
        <div className="product-details my-[30px] flex items-center justify-between">
          <div className="flex flex-col gap-[10px]">
            <h1
              className="text-2xl font-bold cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                navigate(productData.route);
              }}
            >
              {productData.name}
            </h1>
            <span
              className="hidden border border-[#FFFFFF33] rounded-lg py-[10px] px-[14px] bg-[#0B203F] cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                window.open(productData.link, "_blank");
              }}
            >
              {productData.link}
            </span>
          </div>
          <div className="hidden">
            <span
              className=" w-[34px] h-[34px] bg-[#09AAD6] flex items-center justify-center rounded-lg cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                // navigate(productData.route);
                window.open(productData.link, "_blank");
              }}
            >
              <Arrow className="rotate-[315deg]" />
            </span>
          </div>
        </div>
        <p>{productData.description}</p>
      </div>
    </div>
  );
}
