import React from "react";

import { useTranslation } from "react-i18next";
import whoWeAreImage from "../../assets/images/about/who-we-are.png";

const WhoText = () => {
  const { t } = useTranslation();

  return (
    <div className="who-text-section container_bx">
      <div className="text text-center">
        {/* <div className="small-title">{t("who-we-are")}</div> */}
        <span className="text-[20px]">{t("who-we-are")}</span>
        <h1 className="max-w-[814px] m-auto">{t("who-h1")}</h1>
        {/* <p>{t("who-p")}</p> */}
      </div>
      <div className="flex flex-col lg:flex-row flex-wrap xl:flex-nowrap gap-[10px] items-center">
        <div className="who-text-img flex-1">
          <img src={whoWeAreImage} alt="pic" className="!pb-0 max-w-[372px]" />
        </div>
        <div className="flex flex-col gap-[100px] flex-1">
          <div className="label-wrapper">
            <h1 className="!mb-[20px] text-[24px] lg:text-[34px] font-bold">
              {t(`who-we-are-h1`)}
            </h1>
            <p className="text-lg">{t(`who-we-are-p1`)}</p>
          </div>
          <div className="label-wrapper">
            <h1 className="!mb-[20px] text-[24px] lg:text-[34px] font-bold">
              {t(`who-we-are-h2`)}
            </h1>
            <p className="text-lg">{t(`who-we-are-p2`)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoText;
