import React from "react";
import { useTranslation } from "react-i18next";
import "../../assets/css/pages/services.css";
import perfectImg from "../../assets/images/services/digital/1.svg";
import erpImg from "../../assets/images/services/digital/2.svg";
import ContentDetails from "../../components/ContentDetails/ContentDetails";

const DigitalTransformation = () => {
  const { t } = useTranslation();

  const contentData = [
    {
      image: perfectImg,
      details: [
        {
          title: t(`perfect-title-h1`),
        },
        {
          content1: t("perfect-list-p-1"),
        },
        {
          content: t("perfect-list-item-1"),
        },
        {
          content: t("perfect-list-item-2"),
        },
        {
          content: t("perfect-list-item-3"),
        },
        {
          content: t("perfect-list-item-4"),
        },
        {
          content: t("perfect-list-item-5"),
        },
        {
          content: t("perfect-list-item-6"),
        },
        {
          content: t("perfect-list-item-7"),
        },
        {
          content: t("perfect-list-item-8"),
        },
      ],
    },
    {
      image: erpImg,
      details: [
        {
          title: t(`erp-title-h1`),
        },
        {
          content1: t(`erp-list-p-1`),
        },
        {
          content1: t(`erp-list-p-2`),
        },
        {
          content1: t(`erp-list-p-3`),
        },
      ],
    },
  ];
  return (
    <>
      <div className="blog-content-page-section container_bx">
        <div className="title-text w-75 mx-auto text-center">
          <h1>{t("digital-transformation")}</h1>
          {/* <p>{t("services-digital-p")}</p> */}
        </div>

        <div className="product-details-text service-details-wrapper md:!mt-[250px]">
          <div className="flex flex-col gap-[100px]">
            {contentData.map((content, index) => (
              <ContentDetails key={index} contentData={content} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalTransformation;
