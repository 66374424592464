import React from "react";
import { useTranslation } from "react-i18next";
import "../../assets/css/pages/services.css";
import Image1 from "../../assets/images/services/digital-marketing/1.svg";
import Image2 from "../../assets/images/services/digital-marketing/2.svg";
import ContentDetails from "../../components/ContentDetails/ContentDetails";

const DigitalMarketing = () => {
  const { t } = useTranslation();

  const contentData = [
    {
      image: Image1,
      details: [
        {
          title: t(`digital-marketing-p2-main`),
        },
        {
          content: t("digital-marketing-p2-1"),
        },
        {
          content: t("digital-marketing-p2-2"),
        },
        {
          content: t("digital-marketing-p2-3"),
        },
        {
          content: t("digital-marketing-p2-4"),
        },
      ],
    },
    {
      image: Image2,
      details: [
        {
          title: t(`digital-marketing-p3-main`),
        },
        {
          content: t("digital-marketing-p3-1"),
        },
        {
          content: t("digital-marketing-p3-2"),
        },
        {
          content: t("digital-marketing-p3-3"),
        },
      ],
    },
  ];

  return (
    <>
      <div className="container_bx">
        <div className="title-text w-75 mx-auto my-50 text-center">
          {/* <h3>{t("digital-marketing-subtitle")}</h3> */}
          <h1>{t("digital-marketing-title")}</h1>
          {/* <p>{t("digital-marketing-p1")}</p> */}
        </div>

        <div className="product-details-text service-details-wrapper md:!mt-[200px]">
          <div className="flex flex-col gap-[100px]">
            {contentData.map((content, index) => (
              <ContentDetails key={index} contentData={content} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalMarketing;
