import React from "react";
import { useTranslation } from "react-i18next";
import "../../assets/css/pages/services.css";
import Image1 from "../../assets/images/services/testing/1.svg";
import ContentDetails from "../../components/ContentDetails/ContentDetails";

const TestingQA = () => {
  const { t, i18n } = useTranslation();

  const contentData = [
    {
      image: Image1,
      details:
        i18n.language === "ar"
          ? [
              {
                title: t(`testing-qa-p2-title1`),
              },
              {
                content1: t("testing-qa-p2-1"),
              },
              {
                content1: t("testing-qa-p2-2"),
              },
              {
                content: t(`testing-p1-1`),
              },
              {
                content: t(`testing-p1-2`),
              },
              {
                content: t(`testing-p1-3`),
              },
              {
                content: t(`testing-p1-4`),
              },
              {
                content: t(`testing-p1-5`),
              },
              {
                content: t(`testing-p1-6`),
              },
              {
                content: t(`testing-p1-7`),
              },
              {
                content: t(`testing-p1-8`),
              },
            ]
          : [
              {
                title: t(`testing-qa-p2-title1`),
              },
              {
                content1: t("testing-qa-p2-1"),
              },
              {
                content: t(`testing-p1-1`),
              },
              {
                content: t(`testing-p1-2`),
              },
              {
                content: t(`testing-p1-3`),
              },
              {
                content: t(`testing-p1-4`),
              },
              {
                content: t(`testing-p1-5`),
              },
              {
                content: t(`testing-p1-6`),
              },
              {
                content: t(`testing-p1-7`),
              },
              {
                content: t(`testing-p1-8`),
              },
            ],
    },
  ];

  return (
    <>
      <div className="container_bx">
        <div className="title-text w-75 mx-auto my-50 text-center">
          <h1>{t("testing-qa")}</h1>
          {/* <p>{t("testing-qa-p1")}</p> */}
        </div>

        <div className="product-details-text service-details-wrapper md:!mt-[200px]">
          <div className="flex flex-col gap-[100px]">
            {contentData.map((content, index) => (
              <ContentDetails key={index} contentData={content} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TestingQA;
