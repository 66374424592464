import React from "react";

import { useTranslation } from "react-i18next";

import { ReactComponent as WhatWeDoSVG } from "../../assets/images/about/what-we-do.svg";

const WhatWeDoSection = () => {
  const { t } = useTranslation();

  return (
    <div className="what-we-do-section container_bx mt-[50px]">
      <div className="text text-center">
        <span className="text-[20px]">{t("what-we-do")}</span>
        <h1 className="max-w-[814px] m-auto">{t("what-h1")}</h1>
      </div>

      <div className="what-we-do-text">
        <div className="flex flex-col-reverse lg:flex-row flex-wrap xl:flex-nowrap gap-[40px] items-center">
          <div className="flex flex-col gap-[50px] flex-1">
            <div className="label-wrapper">
              <span className="block !text-[20px] !font-normal">
                {t(`vision`)}
              </span>
              <h1 className="!mb-[20px] text-[24px] lg:text-[34px] font-bold">
                {t(`the-#1`)}
              </h1>
              <p className="text-lg">{t(`what-we-do-p1`)}</p>
            </div>
            <div className="label-wrapper">
              <span className="block !text-[20px] !font-normal text-start">
                {t(`mission`)}
              </span>
              <h1 className="!mb-[20px] text-[24px] lg:text-[34px] font-bold">
                {t(`what-we-do-h2`)}
              </h1>
              <p className="text-lg">{t(`what-we-do-p2`)}</p>
            </div>
          </div>
          <div className="who-text-img flex-1 text-center">
            <span className="inline-block !pb-0 max-w-[372px]">
              <WhatWeDoSVG className="w-full h-full" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDoSection;
