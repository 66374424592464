import { Col, Row } from "antd";
import React from "react";

import { useTranslation } from "react-i18next";
import whoImg from "../../assets/images/about/about-us.png";

const PageBanner = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page-banner-section container_bx">
        <h1 className="text-center">{t("about-us")}</h1>
        <span className="font-normal block text-center text-[24px]">
          {t("about-banner-h1")} {t(`wfd`)}
        </span>
      </div>
      <div className="who-text-section container_bx mt-[100px] lg:mt-[0px]">
        <div>
          <Row
            gutter={{
              xs: 20,
              sm: 20,
              md: 20,
              lg: 20,
            }}
            className="items-center"
          >
            <Col className="gutter-row" xs={24} lg={24} xl={12}>
              <div className="text">
                <h1 className="!mb-[20px]">{t(`about-us-h1`)}</h1>
                <p>{t(`about-us-p1`)}</p>
              </div>
            </Col>

            <Col className="gutter-row" xs={24} lg={24} xl={12}>
              <div className="who-text-img">
                <img src={whoImg} alt="pic" className="!pb-0" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PageBanner;
