import React from "react";
import { useTranslation } from "react-i18next";
import "../../assets/css/pages/services.css";
import Image1 from "../../assets/images/services/web/1.svg";
import Image2 from "../../assets/images/services/web/2.svg";
import ContentDetails from "../../components/ContentDetails/ContentDetails";

const WebsiteDevelopment = () => {
  const { t } = useTranslation();

  const contentData = [
    {
      image: Image1,
      details: [
        {
          title: t(`responsive-design`),
        },
        {
          content1: t("web-p1-2"),
        },
      ],
    },
    {
      image: Image2,
      details: [
        {
          title: t("web-p2-title-1"),
        },
        {
          content1: t("web-p2-1"),
        },
        {
          title: t("web-p2-title-3"),
        },
        {
          content1: t("web-p2-3"),
        },
        {
          content1: t("web-p2-4"),
        },
      ],
    },
  ];

  return (
    <>
      <div className="container_bx">
        <div className="title-text w-75 mx-auto my-50 text-center">
          <h1>{t("web-development")}</h1>
        </div>

        <div className="product-details-text service-details-wrapper md:!mt-[250px]">
          <div className="flex flex-col gap-[100px]">
            {contentData.map((content, index) => (
              <ContentDetails key={index} contentData={content} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsiteDevelopment;
