import React from "react";
import { useTranslation } from "react-i18next";

export default function LanguageSwitch() {
  const { t, i18n } = useTranslation();
  document.documentElement.lang = i18n.language;
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        i18n.changeLanguage(i18n.language === "en" ? "ar" : "en");
        window.location.reload();
      }}
    >
      <span className="language-span">{t(`language`)}</span>
    </div>
  );
}
