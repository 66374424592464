import React from "react";
import { useTranslation } from "react-i18next";
import "../../assets/css/pages/services.css";
import Image1 from "../../assets/images/services/mobile/1.svg";
import Image2 from "../../assets/images/services/mobile/2.svg";
import ContentDetails from "../../components/ContentDetails/ContentDetails";

const MobileApplicationDevelopment = () => {
  const { t } = useTranslation();

  const contentData = [
    {
      image: Image1,
      details: [
        {
          title: t(`mob-h1-1`),
        },
        {
          content1: t("mobile-p1-2"),
        },
      ],
    },
    {
      image: Image2,
      details: [
        {
          title: t("mobile-p2-title-1"),
        },
        {
          content1: t("mobile-p2-1"),
        },
        {
          title: t("mobile-p2-title-5"),
        },
        {
          content1: t("mobile-p2-5"),
        },
      ],
    },
  ];

  return (
    <>
      <div className="container_bx">
        <div className="title-text w-75 mx-auto my-50 text-center">
          <h1>{t("mobile-development")}</h1>
          {/* <p>{t("tabs-our-service-p4-3")}</p> */}
        </div>

        <div className="product-details-text service-details-wrapper md:!mt-[250px]">
          <div className="flex flex-col gap-[100px]">
            {contentData.map((content, index) => (
              <ContentDetails key={index} contentData={content} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileApplicationDevelopment;
