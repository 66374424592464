import React from "react";

export default function ContentDetails({ contentData = {} }) {
  return (
    <div className="service-details md:even:flex-row-reverse even:flex-row">
      <div className="data flex-1 min-w-[300px] ">
        {contentData.title && <h1>{contentData.title}</h1>}
        <ul>
          {contentData.details.map((detail, index) => (
            <li key={index}>
              {detail?.title && <h4>{detail?.title}</h4>}
              {detail?.content && detail?.content}
              {detail?.content1 && <p>{detail?.content1}</p>}
            </li>
          ))}
        </ul>
      </div>
      <div className="product-details-frame flex-1">
        <div className="min-w-[250px]">
          <img src={contentData.image} alt="pic" />
        </div>
      </div>
    </div>
  );
}
