import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AISVG } from "../../assets/images/services/svgs/services-AI.svg";
import { ReactComponent as CloudSVG } from "../../assets/images/services/svgs/services-cloud.svg";
import { ReactComponent as DataSVG } from "../../assets/images/services/svgs/services-data.svg";
import { ReactComponent as DigitalMarkitingSVG } from "../../assets/images/services/svgs/services-digital-markiting.svg";
import { ReactComponent as DigitalSVG } from "../../assets/images/services/svgs/services-digital.svg";
import { ReactComponent as MobileSVG } from "../../assets/images/services/svgs/services-mobile.svg";
import { ReactComponent as TestingSVG } from "../../assets/images/services/svgs/services-testing.svg";
import { ReactComponent as UiSVG } from "../../assets/images/services/svgs/services-ui.svg";
import { ReactComponent as WebSVG } from "../../assets/images/services/svgs/services-web.svg";
import DiscoverSection from "../../components/DiscoverSection/DiscoverSection";
import ServiceCard from "../../components/ServiceCard/ServiceCard";

export default function AllServices() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const services = [
    {
      key: "1",
      icon: <DigitalSVG />,
      title: t(`digital-transformation`),
      description: t(`services-digital-p`),
      link: "/services/digital-transformation",
    },
    {
      key: "2",
      icon: <DataSVG />,
      title: t(`data-analytics`),
      description: t(`services-data-p`),
      link: "/services/data-analytics",
    },
    {
      key: "3",
      icon: <WebSVG />,
      title: t(`web-development`),
      description: t(`services-web-p`),
      link: "/services/website-development",
    },
    {
      key: "4",
      icon: <MobileSVG />,
      title: t(`mobile-development`),
      description: t(`services-mobile-p`),
      link: "/services/mobile-application-development",
    },
    {
      key: "5",
      icon: <TestingSVG />,
      title: t(`testing-qa`),
      description: t(`services-testing-p`),
      link: "/services/testing-qa",
    },
    {
      key: "6",
      icon: <UiSVG />,
      title: t(`ux-design`),
      description: t(`services-ui-p`),
      link: "/services/ui-ux-design",
    },
    {
      key: "7",
      icon: <CloudSVG />,
      title: t(`cloud-services`),
      description: t(`services-cloud-p`),
      link: "/services/cloud-services",
    },
    {
      key: "8",
      icon: <AISVG />,
      title: t(`ai-page-h1`),
      description: t(`services-ai-p`),
      link: "/services/ai-services-and-consulting",
    },
    {
      key: "9",
      icon: <DigitalMarkitingSVG />,
      title: t(`digital-marketing-title`),
      description: t(`services-marketing-p`),
      link: "/services/digital-marketing",
    },
  ];
  return (
    <div data-aos="fade-up">
      <div className="product-tabs-bx">
        <div className="text text-center max-w-[700px]">
          <h1 className="!text-center">{t("services")}</h1>
          <p className="!text-center">{t("page-service-p")}</p>
        </div>
        <div className="product-list-wrapper mt-[200px]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap">
            {services.map((service) => (
              <div
                key={service.key}
                className="px-[10px] md:px-[50px] pb-[100px]"
              >
                <ServiceCard serviceData={service} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <DiscoverSection
          title={t(`products-discover-h1`)}
          btnTitle={t(`get-started`)}
          btnClick={() => navigate("/contact")}
        />
      </div>
    </div>
  );
}
