import React from "react";
import { useTranslation } from "react-i18next";
import "../../assets/css/pages/services.css";
import Image1 from "../../assets/images/services/uiux/1.svg";
import ContentDetails from "../../components/ContentDetails/ContentDetails";

const UiUxDesign = () => {
  const { t } = useTranslation();

  const contentData = [
    {
      image: Image1,
      details: [
        {
          title: t(`uiux-h1-1`),
        },
        {
          content1: t("uiux-p2-2"),
        },
        {
          title: t(`uiux-h1-2`),
        },
        {
          content1: t("uiux-p2-3"),
        },
        {
          title: t(`uiux-h1-3`),
        },
        {
          content1: t("uiux-p2-4"),
        },
      ],
    },
  ];

  return (
    <>
      <div className="container_bx">
        <div className="title-text w-75 mx-auto my-50 text-center">
          <h1>{t("ux-design")}</h1>
          {/* <p>{t("uiux-p1")}</p> */}
        </div>

        <div className="product-details-text service-details-wrapper md:!mt-[200px]">
          <div className="flex flex-col gap-[100px]">
            {contentData.map((content, index) => (
              <ContentDetails key={index} contentData={content} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default UiUxDesign;
