import React from "react";
import { useRoutes } from "react-router-dom";
import NoData from "./components/NoData/NoData.jsx";
import About from "./pages/about/About.jsx";
import Contact from "./pages/contact/Contact.jsx";
import Home from "./pages/home/Home.jsx";
import All from "./pages/products/All.jsx";
import DigitalOffice from "./pages/products/DigitalOffice.jsx";
import Imploy from "./pages/products/Imploy.jsx";
import Lms from "./pages/products/Lms.jsx";
import Products from "./pages/products/Products.jsx";
import Shaari from "./pages/products/Shaari.jsx";
import Tshtri from "./pages/products/Tshtri.jsx";
import Warshati from "./pages/products/Warshati.jsx";
import Wfi from "./pages/products/Wfi.jsx";
import AIServicesAndConsulting from "./pages/services/AIServicesAndConsulting.jsx";
import AllServices from "./pages/services/AllServices.jsx";
import CloudServices from "./pages/services/CloudServices.jsx";
import DataAnalytics from "./pages/services/DataAnalytics.jsx";
import DigitalMarketing from "./pages/services/DigitalMarketing.jsx";
import DigitalTransformation from "./pages/services/DigitalTransformation.jsx";
import MobileApplicationDevelopment from "./pages/services/MobileApplicationDevelopment.jsx";
import Services from "./pages/services/Services.jsx";
import TestingQA from "./pages/services/TestingQA.jsx";
import UiUxDesign from "./pages/services/UiUxDesign.jsx";
import WebsiteDevelopment from "./pages/services/WebsiteDevelopment.jsx";
import TermsAndConditios from "./pages/terms-and-conditios.jsx";

export default function AppRouter() {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/about", element: <About /> },
    { path: "/contact", element: <Contact /> },
    // { path: "/blog", element: <Blog /> },
    // { path: "/blogContent", element: <BlogContent /> },
    {
      path: "/services",
      element: <Services />,
      children: [
        { path: "/services", element: <AllServices /> },
        { path: "all", element: <AllServices /> },
        { path: "digital-transformation", element: <DigitalTransformation /> },
        { path: "cloud-services", element: <CloudServices /> },
        { path: "data-analytics", element: <DataAnalytics /> },
        { path: "testing-qa", element: <TestingQA /> },
        { path: "ui-ux-design", element: <UiUxDesign /> },
        {
          path: "mobile-application-development",
          element: <MobileApplicationDevelopment />,
        },
        { path: "website-development", element: <WebsiteDevelopment /> },
        {
          path: "ai-services-and-consulting",
          element: <AIServicesAndConsulting />,
        },
        { path: "digital-marketing", element: <DigitalMarketing /> },
      ],
    },
    { path: "/terms-and-conditions", element: <TermsAndConditios /> },

    {
      path: "/products",
      element: <Products />,
      children: [
        { path: "/products", element: <All /> },
        { path: "all", element: <All /> },
        { path: "shaari", element: <Shaari /> },
        { path: "imploy", element: <Imploy /> },
        { path: "tshtri", element: <Tshtri /> },
        { path: "wfi", element: <Wfi /> },
        { path: "digital-office", element: <DigitalOffice /> },
        { path: "warshati", element: <Warshati /> },
        { path: "lms", element: <Lms /> },
      ],
    },
    {
      path: "*",
      element: <NoData />,
    },
  ]);

  return routes;
}
