import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import become1 from "../../assets/images/become-1.png";
import digitalizing from "../../assets/images/digitalizing.png";
import passion from "../../assets/images/passion.png";
import RightArrow from "../../assets/images/white-right-arrow.svg";

const WhoSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: (
        <div className="image-wrapper">
          <img src={become1} alt="pic" />
        </div>
      ),
      children: (
        <div className="flex flex-col justify-evenly h-full gap-6 my-2">
          <h1 className="text-[34px] font-bold text-white">
            <span className="block !text-[20px] !font-normal">
              {t(`vision`)}
            </span>
            {t(`become`)}
            {"  "}
            <span className=" text-[#2ED2FF]">{t(`the-#1`)}</span>
          </h1>
          <p className="text-lg">{t(`collapse1-text`)}</p>
          <button
            className="w-fit flex gap-4 items-center justify-start text-lg"
            onClick={(e) => {
              e.preventDefault();
              navigate("/about");
            }}
          >
            {t(`get-start`)}
            <img
              className={`w-5 h-5 ${t(`lang`) === "ar" ? "rotate-180" : ""}`}
              src={RightArrow}
              alt="right-arrow"
            />
          </button>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="image-wrapper">
          <img src={digitalizing} alt="pic" />
        </div>
      ),
      children: (
        <div className="flex flex-col justify-evenly h-full gap-6 my-2">
          <h1 className="text-[34px] font-bold text-white w-fit text-start">
            <span className="block !text-[20px] !font-normal text-start">
              {t(`mission`)}
            </span>
            {t(`digitalizing`)}{" "}
            <span className=" text-[#2ED2FF]">{t(`the-mena`)}</span>
          </h1>
          <p className={`text-lg text-start`}>{t(`collapse2-text`)}</p>
          <button
            className="w-fit flex gap-4 items-center justify-start text-lg"
            onClick={(e) => {
              e.preventDefault();
              navigate("/about");
            }}
          >
            {t(`get-start`)}
            <img
              className={`w-5 h-5 ${t("lang") === "ar" ? "rotate-180" : ""}`}
              src={RightArrow}
              alt="right-arrow"
            />
          </button>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="image-wrapper">
          <img src={passion} alt="pic" />
        </div>
      ),
      children: (
        <div className="flex flex-col justify-evenly h-full gap-6 my-2">
          <h1 className="text-[34px] font-bold text-white">
            <span className="block !text-[20px] !font-normal text-start">
              {t(`values`)}
            </span>
            {t(`driven-by`)}{" "}
            <span className=" text-[#2ED2FF]">
              {t(`passion`)} {t(`and-commitment`)}
            </span>
          </h1>
          <p className="text-lg">{t(`collapse3-text`)}</p>
          <button
            className="w-fit flex gap-4 items-center justify-start text-lg"
            onClick={(e) => {
              e.preventDefault();
              navigate("/about");
            }}
          >
            {t(`get-start`)}
            <img
              className={`w-5 h-5 ${t("lang") === "ar" ? "rotate-180" : ""}`}
              src={RightArrow}
              alt="right-arrow"
            />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="who-section container_bx mt-[50px]">
      <div className="who-bx">
        <Row
          gutter={{
            xs: 20,
            sm: 20,
            md: 20,
            lg: 20,
          }}
        >
          <Col className="gutter-row" sm={24} md={24} xl={24}>
            <div className="text text-center">
              {/* <div className="small-title">{t("who-we-are")}</div> */}
              <span className="text-[18px]">{t("who-we-are")}</span>
              <h1 className="max-w-[814px] m-auto">{t("who-h1")}</h1>
              {/* <p>{t("who-p")}</p> */}
            </div>
          </Col>

          <Col className="gutter-row" sm={24} md={24} xl={24}>
            <div className="collapse-container flex flex-col gap-[100px] mt-[100px] max-w-[1200px] m-auto">
              {items.map((item, index) => (
                <div
                  key={item.key}
                  className="flex even:flex-row-reverse flex-wrap lg:flex-nowrap gap-[50px]"
                >
                  <div className="label-wrapper">{item.label}</div>
                  <div
                    className={`${
                      (index + 1) % 2 === 0 ? "text-end" : "text-start"
                    }`}
                  >
                    {item.children}
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default WhoSection;
