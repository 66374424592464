import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right.svg";

export default function DiscoverSection({
  title = "",
  description = "",
  btnTitle = "",
  btnClick = () => {},
}) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="discover-section container_bx">
        <div className="discover-wrapper border border-[#FFFFFF33] rounded-[20px] bg-[#071C3C] py-[121px] px-[20px] md:px-[100px] text-center relative overflow-hidden">
          <div className="overlay-image"></div>
          <div className="block relative z-[999] max-w-[700px] m-auto">
            <h1 className="text-3xl  md:text-5xl font-bold !leading-[4rem]">
              {typeof title === "string" ? title : ""}
            </h1>
            <p className="text-base my-[30px] lg:my-[52px]">
              {typeof description === "string" ? description : ""}
            </p>
            <div className="mt-[52px]">
              <div className="flex items-center justify-center">
                <button
                  className="py-3 px-6 bg-[#09AAD6] rounded-full m-auto text-[24px] flex items-center justify-start gap-[10px] "
                  onClick={(e) => {
                    e.preventDefault();
                    btnClick();
                  }}
                >
                  {typeof btnTitle === "string" ? btnTitle : t(`contact-us`)}
                  <span
                    className={`w-[36px] h-[36px] flex justify-center items-center bg-white rounded-full ${
                      t(`lang`) === "ar" ? "rotate-180" : ""
                    }`}
                  >
                    <ArrowRight />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
