import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import digital from "../../assets/images/products/home-digital-office.png";
import imploy from "../../assets/images/products/home-imploy.png";
// import shaari from "../../assets/images/products/home-shaari.png";
import tshtri from "../../assets/images/products/home-tshtri.png";
import wfi from "../../assets/images/products/home-wafi.png";
import { ReactComponent as Arrow } from "../../assets/images/see-all-arrow-white.svg";
// import tawneer from '../../assets/images/products/tawneer.png';

const ProductSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="products-section container_bx">
      <div className="text">
        <h1>{t("our-products")}</h1>
        {/* <p>{t('our-products-p')}</p> */}
      </div>

      <div className="grid-bx">
        <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}>
          <Col className="gutter-row" sm={24} md={24} xl={6}>
            <div className="item h_100">
              <Link
                to="/products/imploy"
                onClick={() => {
                  window.scroll({ top: 0, behavior: "smooth" });
                }}
              >
                <img src={imploy} alt="pic" className="" />
                <div className="project-overlay"></div>

                <div className="absolute bottom-0 left-0 right-0 p-7">
                  <h1 className="text-4xl font-medium">{t(`Imploy`)}</h1>
                  <p className="text-base font-normal">{t(`Platform`)}</p>
                </div>
                <div className="item-data">
                  <div className="flex items-center justify-between gap-1 w-full flex-wrap">
                    <div>
                      <h1 className="text-5xl font-medium">{t("Imploy")}</h1>
                      <p className="text-base font-normal">{t("Platform")}</p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <span className="text-base font-medium">
                        {t(`check-now`)}
                      </span>
                      <span className="inline-block w-6 h-6 rotate-arrow">
                        <Arrow />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </Col>

          <Col className="gutter-row" sm={24} md={24} xl={12}>
            <div className="shaari-wrapper">
              <div className="item h_50 relative">
                <Link
                  className="block w-full h-full relative"
                  to="/products/shaari"
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                >
                  {/* <img src={shaari} alt="pic" /> */}
                  <div className={`shaari-bg-image`}></div>
                  <div className="project-overlay"></div>

                  <div className="absolute bottom-0 left-0 right-0 p-7">
                    <h1 className="text-4xl font-medium">{t(`Shaari`)}</h1>
                    <p className="text-base font-normal">{t(`Platform`)}</p>
                  </div>
                  <div className="item-data">
                    <div className="flex items-center justify-between gap-1 w-full flex-wrap">
                      <div>
                        <h1 className="text-5xl font-medium">{t("Shaari")}</h1>
                        <p className="text-base font-normal">{t("Platform")}</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <span className="text-base font-medium">
                          {t(`check-now`)}
                        </span>
                        <span className="inline-block w-6 h-6 rotate-arrow">
                          <Arrow />
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="twice-bx">
              <div className="item h_50">
                <Link
                  className="block w-full h-full relative"
                  to="/products/wfi"
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                >
                  <img src={wfi} alt="pic" />
                  <div className="project-overlay"></div>

                  {/* <div className="hidden absolute top-10 left-10">
                    <img src={wafiTitle} alt="pic" />
                  </div> */}
                  <div className="absolute bottom-0 left-0 right-0 p-7">
                    <h1 className="text-4xl font-medium">{t(`WFI`)}</h1>
                    <p className="text-base font-normal">{t(`Platform`)}</p>
                  </div>
                  <div className="item-data">
                    <div className="flex items-center justify-between gap-1 w-full flex-wrap">
                      <div>
                        <h1 className="text-5xl font-medium">{t("WFI")}</h1>
                        <p className="text-base font-normal">{t("Platform")}</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <span className="text-base font-medium">
                          {t(`check-now`)}
                        </span>
                        <span className="inline-block w-6 h-6 rotate-arrow">
                          <Arrow />
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="item h_50">
                <Link
                  className="block w-full h-full relative"
                  to="/products/digital-office"
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                >
                  <img src={digital} alt="pic" />
                  <div className="project-overlay"></div>

                  <div className="absolute bottom-0 left-0 right-0 p-7">
                    <h1 className="text-4xl font-medium">
                      {t(`digital-office`)}
                    </h1>
                    <p className="text-base font-normal">{t(`ERP System`)}</p>
                  </div>
                  <div className="item-data">
                    <div className="flex items-start justify-between gap-1 w-full flex-col">
                      <div>
                        <h1 className="text-3xl font-medium">
                          {t("digital-office")}
                        </h1>
                        <p className="text-base font-normal">{t("Platform")}</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <span className="text-base font-medium">
                          {t(`check-now`)}
                        </span>
                        <span className="inline-block w-6 h-6 rotate-arrow">
                          <Arrow />
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </Col>

          <Col className="gutter-row" sm={24} md={24} xl={6}>
            <div className="item h_100">
              <Link
                className="block w-full h-full relative"
                to="/products/tshtri"
                onClick={() => {
                  window.scroll({ top: 0, behavior: "smooth" });
                }}
              >
                <img src={tshtri} alt="pic" />
                <div className="project-overlay"></div>
                <div className="absolute bottom-0 left-0 right-0 p-7">
                  <h1 className="text-4xl font-medium">{t(`Tshtri`)}</h1>
                  <p className="text-base font-normal">{t(`Platform`)}</p>
                </div>
                {/* <div className="absolute top-10 left-5">
                  <img className="!rounded-none" src={tshtriTitle} alt="pic" />
                </div> */}
                <div className="item-data">
                  <div className="flex items-center justify-between gap-1 w-full flex-wrap">
                    <div>
                      <h1 className="text-3xl font-medium">{t("Tshtri")}</h1>
                      <p className="text-base font-normal">{t("Platform")}</p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <span className="text-base font-medium">
                        {t(`check-now`)}
                      </span>
                      <span className="inline-block w-6 h-6 rotate-arrow">
                        <Arrow />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
      <div className="mt-[80px] mb-[67px]">
        <button
          className="m-auto block py-[22px] px-[45px] border rounded-[35px]"
          onClick={(e) => {
            e.preventDefault();
            navigate("/products/all");
          }}
        >
          {t(`view-all-products`)}
        </button>
      </div>
    </div>
  );
};

export default ProductSection;
