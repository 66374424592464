import React from "react";
import "./Spinner.css";

export default function Spinner() {
  return (
    <div>
      <div className="spinner-section">
        <div></div>
      </div>
    </div>
  );
}
