import Swal from "sweetalert2";

const sweat = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  background: "#212121",
  color: "#fff",
});

const sweatAlert = {
  success: (text, position = "top-end") => {
    sweat.fire({
      icon: "success",
      title: text,
      position: position,
    });
  },

  error: (text, position = "top-end") => {
    sweat.fire({
      icon: "error",
      title: text,
      position: position,
    });
  },
};

export default sweatAlert;
