import React from 'react';
import BannerSection from './BannerSection';
import WelcomeSection from './WelcomeSection';
import WhoSection from './WhoSection';
import ServiceSection from './ServiceSection';
import ProductSection from './ProductSection';
import DiscoverSection from './DiscoverSection';
import BlogSection from './BlogSection';
import '../../assets/css/pages/home.css';

const Home = () => {
    return (
        <>
            <div data-aos="fade-up">
                <BannerSection />
            </div>

            <div data-aos="fade-up">
                <WelcomeSection />
            </div>

            <div data-aos="fade-up">
                <WhoSection />
            </div>

            <div data-aos="fade-up">
                <ServiceSection />
            </div>

            <div data-aos="fade-up">
                <ProductSection />
            </div>

            {/* <div data-aos="fade-up">
                <BlogSection />
            </div> */}

            <div data-aos="fade-up">
                <DiscoverSection />
            </div>
        </>
    );
}

export default Home;