import { Button, Checkbox, Col, Form, Input, Row, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Phone } from "../../assets/images/contact/call.svg";
import { ReactComponent as Address } from "../../assets/images/contact/location.svg";
import { ReactComponent as Email } from "../../assets/images/contact/sms.svg";
import { ReactComponent as FacebookSVG } from "../../assets/images/footer-facebook.svg";
import { ReactComponent as InstagramSVG } from "../../assets/images/footer-insta.svg";
import { ReactComponent as LinkedInSVG } from "../../assets/images/footer-linkedin.svg";
import { ReactComponent as XSVG } from "../../assets/images/footer-x.svg";
import sweatAlert from "../../helpers/sweatAlert.js";
import { createLead } from "../../services/commonAPIs.js";

const ContactSection = ({ loading, setLoading = () => {} }) => {
  const [subjectValues, setSubjectValues] = useState([]);
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const lang = i18n.language;

  const subjectOptions = [
    {
      label: t(`web-development`),
      value: "Web Development",
    },
    {
      label: t(`digital-transform`),
      value: "Digital Transformation",
    },
    {
      label: t(`mobile-app-development`),
      value: "Mobile App Development",
    },
    {
      label: t(`others`),
      value: "Others",
    },
  ];

  const socialMedia = [
    {
      key: 1,
      title: "facebook",
      icon: <FacebookSVG />,
      link: `https://www.facebook.com/wfdEgypt`,
    },
    {
      key: 2,
      title: "youtube",
      icon: <InstagramSVG />,
      link: `https://www.instagram.com/wfdEgypt`,
    },
    {
      key: 3,
      title: "x",
      icon: <XSVG />,
      link: `https://twitter.com/wfdEgypt`,
    },
    {
      key: 4,
      title: "linkedIn",
      icon: <LinkedInSVG />,
      link: `https://www.linkedin.com/company/wfdegypt/`,
    },
  ];

  const onChange = (checkedValues) => {
    setSubjectValues(checkedValues);
  };

  const onFinish = async (values) => {
    // if (!values?.privacy) return sweatAlert.error(t('privacy-validation'));
    // delete values.privacy;

    // const formdata = new FormData();
    // Object.keys(values).forEach((key) => { formdata.append(key, values[key]); });

    if (!values.full_name || !values.email || !values.phone || !values.message)
      return;

    setLoading(true);
    const formdata = {
      contact_name: `${values.full_name || ""}`,
      email_from: values.email || "",
      name: `WFD website - ${values.full_name || ""}`,
      phone: values.phone || "",
      // description: values.message || "",
      description: `${
        subjectValues?.length > 0
          ? `Interests: ${subjectValues.join(", ")}\n\n`
          : ""
      }Message: ${values.message || " "}`,
      source_id: 10,
    };

    try {
      const { data } = await createLead(formdata);
      sweatAlert.success(
        (lang === "en" ? data?.message?.en : data?.message?.ar) ||
          t("success-message"),
        lang === "en" ? "top-end" : "top-start",
      );
      setTimeout(() => {
        form.resetFields();
        setSubjectValues([]);
      }, 400);
    } catch (error) {
      sweatAlert.error(
        error?.response?.data?.message || t("error-message"),
        lang === "en" ? "top-end" : "top-start",
      );
      console.log(error);
    }

    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // sweatAlert.error(
    //   errorInfo?.errorFields[0]?.errors[0] || t("error-message")
    // );
  };

  const { TextArea } = Input;

  // if (loading) return <Spinner />;

  return (
    <>
      <div className="contact-section container_bx mt-[50px]">
        <div className="text">
          <h1>{t("contact-page-h1")}</h1>
          <p>{t("contact-page-p")}</p>
        </div>

        <div className="pt_50 pb_50">
          <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}>
            <Col className="gutter-row" xs={24} md={12} xl={12}>
              <div className="contact-form-bx">
                <div>
                  <Form
                    name="basic"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    autoComplete="off"
                  >
                    <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}>
                      <Col className="gutter-row" xs={24} md={12} xl={12}>
                        <Form.Item
                          label={t("full-name")}
                          name="full_name"
                          rules={[
                            { required: true, message: t("fname-validation") },
                            {
                              pattern: /^[a-zA-Z\u0600-\u06FF\s]*$/,
                              message: t("name-invalid"),
                            },
                            {
                              validator: (_, value) => {
                                if (value && !value.trim()) {
                                  return Promise.reject(t("name-invalid"));
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder={t("enter-full-name")}
                            className="contact-input"
                            maxLength={140}
                            onKeyPress={(e) => {
                              if (!/^[a-zA-Z\u0600-\u06FF\s]*$/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" xs={24} md={12} xl={12}>
                        <Form.Item
                          label={t("phone")}
                          name="phone"
                          rules={[
                            { required: true, message: t("phone-validation") },
                            {
                              pattern:
                                /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                              message: t("phone-invalid"),
                            },
                            {
                              validator: (_, value) => {
                                if (value && !value.trim()) {
                                  return Promise.reject(t("phone-invalid"));
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Space.Compact>
                            {/* <Select defaultValue="tel" options={options} size="large" /> */}
                            <Input
                              size="large"
                              placeholder={t("enter-your-phone")}
                              max={140}
                              maxLength={140}
                              onKeyPress={(e) => {
                                if (
                                  !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(
                                    e.key,
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Space.Compact>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item
                      label={t("email")}
                      name="email"
                      rules={[
                        { required: true, message: t("email-validation") },
                        { type: "email", message: t("email-invalid") },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("enter-your-email")}
                        max={140}
                        maxLength={140}
                      />
                    </Form.Item>

                    <div className="subject-wrapper my-[46px]">
                      <h1 className="mb-[20px] text-white text-lg font-medium">
                        {t(`contact-subject-h1`)}
                      </h1>
                      <Checkbox.Group
                        options={subjectOptions}
                        value={subjectValues}
                        onChange={onChange}
                      />
                    </div>

                    {/* <Form.Item
                    label={t("subject")}
                    name="subject"
                    rules={[
                      { required: true, message: t("subject-validation") },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t("subject-placeholder")}
                    />
                  </Form.Item> */}

                    <Form.Item
                      label={t("message")}
                      name="message"
                      rules={[
                        { required: true, message: t("message-validation") },
                        {
                          validator: (_, value) => {
                            if (value && !value.trim()) {
                              return Promise.reject(t("message-invalid"));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>

                    {/* <Form.Item
                                        name="privacy"
                                        valuePropName="checked"
                                    >
                                        <Checkbox>{t('privacy-text')} <Link to='/'>{t('privacy-policy')}</Link>.</Checkbox>
                                    </Form.Item> */}

                    <Form.Item>
                      <Button
                        type="primary"
                        className="validation-btn"
                        htmlType="submit"
                      >
                        {t("validate-btn-text")}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Col>

            <Col className="gutter-row" sm={24} md={12} xl={12}>
              <div className="details-text">
                <h1>
                  <Address />
                  {t("address-branch")}
                </h1>
                <ul className="list-disc">
                  <li
                    className="cursor-pointer hover:text-[#2ED2FF] transition-all"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://maps.app.goo.gl/tLeXQBFvPiYQV1MMA",
                        "_blank",
                        "noopener,noreferrer",
                      );
                    }}
                  >
                    {t("adress-list-1")}
                  </li>
                  <li
                    className="cursor-pointer hover:text-[#2ED2FF] transition-all"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://maps.app.goo.gl/a83u9k8FPvbfe3xh8",
                        "_blank",
                        "noopener,noreferrer",
                      );
                    }}
                  >
                    {t("adress-list-2")}
                  </li>
                </ul>

                <h1>
                  <Phone /> {t("phone-branch")}
                </h1>
                <ul className="list-disc">
                  <li>
                    {t("phone-list-1")}
                    {/* <span
                      className={`inline-block mx-1 ${t(
                        "lang" === "en" ? "" : "main-dir-ltr",
                      )}`}
                    >
                      {t(`phone-1`)}
                    </span> */}
                  </li>
                  <li>
                    {t("phone-list-2")}
                    {/* <span
                      className={`inline-block mx-1 ${t(
                        "lang" === "en" ? "" : "main-dir-ltr",
                      )}`}
                    >
                      {t(`phone-2`)}
                    </span> */}
                  </li>
                </ul>

                <h1>
                  <Email /> {t("email-branch")}
                </h1>
                <ul className="list-disc">
                  <li>{t("email-list-1")}</li>
                </ul>
              </div>
              <div className="extra-info flex items-center justify-start gap-[100px] flex-wrap mt-[60px] ms-[20px]">
                <div className="operating-days hidden">
                  <h1 className="text-[22px] font-medium text-[#E6E6E6] mb-[25px]">
                    {t("operating-days")}
                  </h1>
                  <p className="text-[20px] font-normal text-[#E6E6E6]">
                    {t("operating-days-text")}
                  </p>
                </div>
                <div className="stay-connected">
                  <h1 className="text-[22px] font-medium text-[#E6E6E6] mb-[25px]">
                    {t(`stay-connected`)}
                  </h1>
                  <div className="social-wrapper flex gap-[10px] items-center justify-start">
                    {socialMedia.map((item) => {
                      return (
                        <span
                          key={item.key}
                          className="inline-block w-[32px] h-[32px] cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              item.link,
                              "_blank",
                              "noopener,noreferrer",
                            );
                          }}
                        >
                          {item.icon}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ContactSection;
