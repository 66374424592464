import { Col, Row } from "antd";
import React from "react";

import { useTranslation } from "react-i18next";

import img1 from "../../assets/images/team/1.png";
import img2 from "../../assets/images/team/2.png";
import img3 from "../../assets/images/team/3.png";
import img4 from "../../assets/images/team/4.png";

const TeamSection = () => {
  const { t } = useTranslation();

  return (
    <div className="team-section container_bx mt-[100px]">
      <div className="text">
        <h1>{t("team-h1")}</h1>
      </div>

      <div className="team-items">
        <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}>
          <Col className="gutter-row" xs={24} md={12} xl={6}>
            <div className="member-item">
              <img src={img1} alt="pic" />
              <h3>Hesham Al Alshaikh</h3>
              <p>Founder and Chairman</p>
            </div>
          </Col>

          <Col className="gutter-row" xs={24} md={12} xl={6}>
            <div className="member-item">
              <img src={img2} alt="pic" />
              <h3>Abdullah Al Alshaikh</h3>
              <p>CEO & Board Member</p>
            </div>
          </Col>

          <Col className="gutter-row" xs={24} md={12} xl={6}>
            <div className="member-item">
              <img src={img3} alt="pic" />
              <h3>Haytham Bedeir</h3>
              <p>CTO & Board Member</p>
            </div>
          </Col>

          <Col className="gutter-row" xs={24} md={12} xl={6}>
            <div className="member-item">
              <img src={img4} alt="pic" />
              <h3>Hossam Hassan</h3>
              <p>MD & Board Member</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TeamSection;
