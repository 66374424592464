import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DataSVG } from "../../assets/images/services/svgs/services-data.svg";
import { ReactComponent as DigitalSVG } from "../../assets/images/services/svgs/services-digital.svg";
import { ReactComponent as MobileSVG } from "../../assets/images/services/svgs/services-mobile.svg";
import { ReactComponent as TestingSVG } from "../../assets/images/services/svgs/services-testing.svg";
import { ReactComponent as UiSVG } from "../../assets/images/services/svgs/services-ui.svg";
import { ReactComponent as WebSVG } from "../../assets/images/services/svgs/services-web.svg";
import CheckNowBtn from "../../components/CheckNowBtn/CheckNowBtn";

const ServiceSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const updatedItems = [
    {
      key: "1",
      icon: <DigitalSVG />,
      title: t(`digital-transformation`),
      description: t(`tabs-our-service-p1-3`),
      link: "/services/digital-transformation",
    },
    {
      key: "2",
      icon: <DataSVG />,
      title: t(`data-analytics`),
      description: t(`tabs-our-service-p2-3`),
      link: "/services/data-analytics",
    },
    {
      key: "3",
      icon: <WebSVG />,
      title: t(`web-development`),
      description: t(`tabs-our-service-p3-3`),
      link: "/services/website-development",
    },
    {
      key: "4",
      icon: <MobileSVG />,
      title: t(`mobile-development`),
      description: t(`tabs-our-service-p4-3`),
      link: "/services/mobile-application-development",
    },
    {
      key: "5",
      icon: <TestingSVG />,
      title: t(`testing-qa`),
      description: t(`tabs-our-service-p6-3`),
      link: "/services/testing-qa",
    },
    {
      key: "6",
      icon: <UiSVG />,
      title: t(`ux-design`),
      description: t(`tabs-our-service-p5-3`),
      link: "/services/ui-ux-design",
    },
  ];

  return (
    <>
      <div className="services-section container_bx mt-[100px]">
        <div className="text">
          <div className="text-center text-lg">{t("our-service")}</div>
          <h1 className="">{t("our-service-h1")}</h1>
          {/* <p>{t("our-service-p")}</p> */}
        </div>
      </div>

      <div className="services-section mt-[100px]">
        <div className="services-wrapper tabs-bx">
          <div className="container_bx">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[42px] gap-y-[150px]">
              {updatedItems?.map((item) => (
                <div key={item.key} className="service-item flex flex-col">
                  <div className="icon-wrapper">
                    <span className="inline-block w-[60px] h-[60px]">
                      {item.icon}
                    </span>
                  </div>
                  <h1 className="mt-[15px] text-3xl font-bold">{item?.title}</h1>
                  <p className="my-[15px] flex-1">{item?.description}</p>
                  <CheckNowBtn link={item.link} />
                </div>
              ))}
            </div>
            <div className="mt-[80px] mb-[67px]">
              <button
                className="m-auto block py-[22px] px-[45px] border rounded-[35px]"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/services/all");
                }}
              >
                {t(`check-all-service`)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceSection;
