import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import "../../assets/css/pages/products.css";

const Products = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="products__wrapper mt-[75px]">
        <div data-aos="fade-up">
          <div className="products-tabs-section container_bx">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
