import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { TfiAlignRight } from "react-icons/tfi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../../assets/css/shared/header.css";
import whiteLogo from "../../assets/images/white-logo.svg";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";

const Header = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [stickyClass, setStickyClass] = useState("");
  const [checked, setChecked] = useState(false);
  document.documentElement.lang = i18n.language;

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 70 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  return (
    <div className="header-section container_bx">
      <div className={`menu-bx top-header main-menu ${stickyClass}`}>
        <div className="menu-content container_bx">
          <div
            className="logo"
            onClick={() => {
              navigate("/");
              window.scroll({ top: 0, behavior: "smooth" });
            }}
          >
            <img src={whiteLogo} alt="pic" />
          </div>
          <div className="wrapper" style={{ flex: 1 }}>
            <input
              type="radio"
              name="slider"
              id="menu-btn"
              value={checked}
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <input
              type="radio"
              name="slider"
              id="close-btn"
              value={checked}
              checked={checked}
              onChange={() => setChecked(!checked)}
            />

            <ul className="nav-links">
              <label htmlFor="close-btn" className="btn close-btn">
                <AiOutlineClose />
              </label>
              <li>
                <NavLink
                  to="/"
                  className="desktop-item"
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                >
                  {t("home")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services/all"
                  className=" desktop-item"
                  onClick={(e) => {
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                >
                  {t("services")}
                </NavLink>
                {/* Used for mobile view */}
                <input type="checkbox" id="showDrop" />
                <label htmlFor="showDrop" className="mobile-item">
                  {t("service")}
                </label>
                <ul className="drop-menu">
                  <li>
                    <Link
                      onClick={() => {
                        setChecked(false);
                      }}
                      to="/services/digital-transformation"
                    >
                      {t("digital-transformation")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setChecked(false);
                      }}
                      to="/services/data-analytics"
                    >
                      {t("data-analytics")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setChecked(false);
                      }}
                      to="/services/website-development"
                    >
                      {t("web-development")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setChecked(false);
                      }}
                      to="/services/mobile-application-development"
                    >
                      {t("mobile-development")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setChecked(false);
                      }}
                      to="/services/testing-qa"
                    >
                      {t("testing-qa")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setChecked(false);
                      }}
                      to="/services/ui-ux-design"
                    >
                      {t("ux-design")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setChecked(false);
                      }}
                      to="/services/cloud-services"
                    >
                      {t("cloud-services")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      onClick={() => {
                        setChecked(false);
                      }}
                      to="/services/ai-services-and-consulting"
                    >
                      {t("ai-page-h1")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        setChecked(false);
                      }}
                      to="/services/digital-marketing"
                    >
                      {t("digital-marketing-title")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="hidden">
                <NavLink
                  to="/blog"
                  className="desktop-item"
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                >
                  {t("blog")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/products/all"
                  className="desktop-item"
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                >
                  {t("digital-products")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  className="desktop-item"
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                >
                  {t("about-us")}
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/contact"
                  className="desktop-item"
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                >
                  {t("contact")}
                </NavLink>
              </li> */}
              <li className="actions-items-list">
                <button
                  className="contact-btn bg-white text-[#041735] leading-3"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/contact");
                  }}
                >
                  {t(`contact-us`)}
                </button>
                <LanguageSwitch />
              </li>
            </ul>

            <label htmlFor="menu-btn" className="btn menu-btn">
              <TfiAlignRight />
            </label>

            {/* <div className='choose-lang hide-desktop'>
                            {i18n.language === 'en' && <span onClick={() => { i18n.changeLanguage('ar') }}>عربي</span>}
                            {i18n.language === 'ar' && <span onClick={() => { i18n.changeLanguage('en') }}>English</span>}
                            <img src={language} alt='pic' />
                        </div> */}
          </div>
          <div className="actions-wrapper">
            <button
              className="contact-btn bg-white text-[#041735] leading-3"
              onClick={(e) => {
                e.preventDefault();
                navigate("/contact");
              }}
            >
              {t(`contact-us`)}
            </button>
            <div className="">
              <LanguageSwitch />
            </div>
          </div>
          {/* <div className='choose-lang hide-mobile'>
                        {i18n.language === 'en' && <span onClick={() => { i18n.changeLanguage('ar') }}>عربي</span>}
                        {i18n.language === 'ar' && <span onClick={() => { i18n.changeLanguage('en') }}>english</span>}
                        <img src={language} alt='pic' />
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
