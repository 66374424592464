import React from "react";
import { useTranslation } from "react-i18next";
import digital1 from "../../assets/images/products/digital/1.png";
import digital2 from "../../assets/images/products/digital/2.png";
import digital3 from "../../assets/images/products/digital/3.png";
import ContentDetails from "../../components/ContentDetails/ContentDetails";

const DigitalOffice = () => {
  const { t, i18n } = useTranslation();

  const contentData = [
    {
      image: digital1,
      title: i18n.language === "ar" ? t(`about-digital-office`) : t(`about`),
      details: [
        {
          content1: t("digital-p1-1"),
        },
        {
          content1: t("digital-p1-2"),
        },
        {
          content1: t("digital-p1-3"),
        },
      ],
    },
    {
      image: digital2,
      title: i18n.language === "ar" ? t(`digital-h2`) : t(`customer-needs`),
      details:
        i18n.language === "ar"
          ? [
              {
                content: t("digital-p2-1"),
              },

              {
                content: t("digital-p2-2"),
              },
            ]
          : [
              {
                content: t("digital-p2-1"),
              },

              {
                content: t("digital-p2-2"),
              },

              {
                content: t("digital-p2-3"),
              },

              {
                content: t("digital-p2-4"),
              },
            ],
    },
    {
      image: digital3,
      title: i18n.language === "ar" ? t(`digital-h3`) : t(`solutions`),
      details:
        i18n.language === "ar"
          ? [
              {
                content: t("digital-p3-1"),
              },
            ]
          : [
              {
                content: t("digital-p3-1"),
              },
              {
                content: t("digital-p3-2"),
              },
              {
                content: t("digital-p3-3"),
              },
              {
                content: t("digital-p3-4"),
              },
            ],
    },
  ];

  return (
    <div data-aos="fade-up">
      <div className="title-text w-75 mx-auto my-50 text-center">
        <h1>{t("digital-office")}</h1>
      </div>

      <div className="product-details-text service-details-wrapper md:!mt-[250px]">
        <div className="flex flex-col gap-[100px]">
          {contentData.map((content, index) => (
            <ContentDetails key={index} contentData={content} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DigitalOffice;
