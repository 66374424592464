import React from "react";
import { useTranslation } from "react-i18next";
import lms1 from "../../assets/images/products/lms/1.png";
import lms2 from "../../assets/images/products/lms/2.png";
import lms3 from "../../assets/images/products/lms/3.png";
import ContentDetails from "../../components/ContentDetails/ContentDetails";

const Lms = () => {
  const { t, i18n } = useTranslation();

  const contentData = [
    {
      image: lms1,
      title: i18n.language === "ar" ? t(`about-lms`) : t(`about`),
      details: [
        {
          content1: t("lms-p1-1"),
        },
        {
          content1: t("lms-p1-2"),
        },
        {
          content1: t("lms-p1-3"),
        },
        {
          content1: t("lms-p1-4"),
        },
      ],
    },
    {
      image: lms2,
      title: i18n.language === "ar" ? t(`lms-h2`) : t(`customer-needs`),
      details:
        i18n.language === "ar"
          ? [
              {
                content: t("lms-p2-1"),
              },
              {
                content: t("lms-p2-2"),
              },
            ]
          : [
              {
                content: t("lms-p2-1"),
              },
              {
                content: t("lms-p2-2"),
              },
              {
                content: t("lms-p2-3"),
              },
              {
                content: t("lms-p2-4"),
              },
            ],
    },
    {
      image: lms3,
      title: i18n.language === "ar" ? t(`lms-h3`) : t(`solutions`),
      details:
        i18n.language === "ar"
          ? [
              {
                content: t("lms-p3-1"),
              },
              {
                content: t("lms-p3-2"),
              },
            ]
          : [
              {
                content: t("lms-p3-1"),
              },
              {
                content: t("lms-p3-2"),
              },
              {
                content: t("lms-p3-3"),
              },
              {
                content: t("lms-p3-4"),
              },
            ],
    },
  ];

  return (
    <div data-aos="fade-up">
      <div className="title-text w-75 mx-auto my-50 text-center">
        <h1>{t("LMS")}</h1>
      </div>

      <div className="product-details-text service-details-wrapper md:!mt-[150px]">
        <div className="flex flex-col gap-[100px]">
          {contentData.map((content, index) => (
            <ContentDetails key={index} contentData={content} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Lms;
