import React from "react";
import { useTranslation } from "react-i18next";
// import { ReactComponent as Arrow } from "../../assets/images/products/arrow.svg";
import { useNavigate } from "react-router-dom";
import imploy from "../../assets/images/products/Imploy.jpg";
import digital from "../../assets/images/products/digital-office.jpg";
import lms from "../../assets/images/products/lms.jpg";
import shaari from "../../assets/images/products/shaari.png";
import tshtri from "../../assets/images/products/tshtri.jpg";
import warshati from "../../assets/images/products/warshati.jpg";
import wfi from "../../assets/images/products/wfi.jpg";
import DiscoverSection from "../../components/DiscoverSection/DiscoverSection";
import ProductCard from "../../components/Products/ProductCard";

const All = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const products = [
    {
      id: 1,
      name: t(`Imploy`),
      image: imploy,
      route: "/products/imploy",
      link: "https://www.immploy.com/",
      description: t(`products-imploy-discription`),
    },
    {
      id: 2,
      name: t(`Shaari`),
      image: shaari,
      route: "/products/shaari",
      link: "https://www.shaari.com/",
      description: t(`products-shaari-discription`),
    },
    {
      id: 4,
      name: t(`WFI`),
      image: wfi,
      route: "/products/wfi",
      link: "https://www.wfi.com/",
      description: t(`products-wfi-discription`),
    },
    {
      id: 3,
      name: t(`Tshtri`),
      image: tshtri,
      route: "/products/tshtri",
      link: "https://www.tshtri.com/",
      description: t(`products-tshtri-discription`),
    },
    {
      id: 5,
      name: t(`digital-office`),
      image: digital,
      route: "/products/digital-office",
      link: "https://www.digitaloffice.com/",
      description: t(`products-marketing-discription`),
    },
    {
      id: 7,
      name: t(`LMS`),
      image: lms,
      route: "/products/lms",
      link: "https://www.lms.com/",
      description: t(`products-lms-discription`),
    },
    {
      id: 6,
      name: t(`Warshati`),
      image: warshati,
      route: "/products/warshati",
      link: "https://www.warshati.com/",
      description: t(`products-warshati-discription`),
    },
  ];

  return (
    <div data-aos="fade-up">
      <div className="product-tabs-bx">
        <div className="text text-center max-w-[700px]">
          <h1 className="!text-center">{t("our-products-h1")}</h1>
          <h2 className="text-5xl font-medium mb-[20px]">+7 {t(`products`)}</h2>
          <p className="!text-center">{t("our-products-p")}</p>
        </div>
        <div className="product-list-wrapper">
          <div className="grid grid-cols-1 md:grid-cols-2 gap">
            {products.map((product) => (
              <div
                key={product.id}
                className="px-[10px] md:px-[50px] pb-[100px]"
              >
                <ProductCard productData={product} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <DiscoverSection
          title={t(`products-discover-h1`)}
          btnTitle={t(`contact-us`)}
          btnClick={() => navigate("/contact")}
        />
      </div>
    </div>
  );
};

export default All;
