import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import RightArrow from "../../assets/images/white-right-arrow.svg";

export default function CheckNowBtn({ link = "" }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <button
        className="w-fit flex gap-4 items-center justify-start text-lg"
        onClick={(e) => {
          e.preventDefault();
          navigate(link ? link : "/");
        }}
      >
        {t(`get-start`)}
        <img
          className={`w-5 h-5 ${t(`lang`) === "ar" ? "rotate-180" : ""}`}
          src={RightArrow}
          alt="right-arrow"
        />
      </button>
    </>
  );
}
