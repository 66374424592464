import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactTyped } from "react-typed";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right.svg";

const BannerSection = () => {
  const { t, i18n } = useTranslation();
  let lang = i18n.language;
  const navigate = useNavigate();

  return (
    <div className="banner-section container_bx mt-[50px]">
      <Row
        gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }}
        style={{ alignItems: "center", justifyContent: "center" }}
      >
        <Col className="gutter-row text" sm={18} md={18} xl={24}>
          {/* <h1>{t('banner-h1')}</h1> */}
          <ReactTyped
            key={lang}
            className="typed"
            strings={[
              `<span style="color: white;">${t(
                `banner-h1`
              )} <br/> <span style="color: #2ED2FF;">${t(
                `banner-h2`
              )}</span></span>`,
            ]}
            backDelay={5000}
            typeSpeed={50}
            backSpeed={50}
            contentType="html"
          />
        </Col>
      </Row>
      <div className="mt-[33px]">
        <div className="border rounded-[62px] border-[#FFFFFF33] py-6 px-[10px] md:px-[40px] w-fit m-auto flex items-center justify-center flex-wrap gap-[6px]">
          {t(`for`)}
          <span className="bg-[#041735] rounded-full py-3 px-[14px]">
            {t(`startups`)}
          </span>
          ,
          <span className="bg-[#041735] rounded-full py-3 px-[14px]">
            {t(`enterprises`)}
          </span>
          ,
          <span className="bg-[#041735] rounded-full py-3 px-[14px]">
            {t(`companies`)}
          </span>
          {t(`and`)}
          <span className="bg-[#041735] rounded-full py-3 px-[14px]">
            {t(`organizations`)}
          </span>
        </div>
      </div>
      <div className="mt-[60px]">
        <div className="flex items-center justify-center">
          <button
            className="py-3 px-6 bg-[#09AAD6] rounded-full m-auto text-[24px] flex items-center justify-start gap-[10px] "
            onClick={(e) => {
              e.preventDefault();
              navigate("/contact");
            }}
          >
            {t(`get-started`)}
            <span
              className={`w-[36px] h-[36px] flex justify-center items-center bg-white rounded-full ${
                t(`lang`) === "ar" ? "rotate-180" : ""
              }`}
            >
              <ArrowRight />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BannerSection;
