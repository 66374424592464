import React from "react";
import CheckNowBtn from "../CheckNowBtn/CheckNowBtn";

export default function ServiceCard({ serviceData = {} }) {
  return (
    <div className="h-full">
      <div key={serviceData.key} className="service-item flex flex-col h-full">
        <div className="icon-wrapper">
          <span className="inline-block w-[60px] h-[60px]">
            {serviceData.icon}
          </span>
        </div>
        <h1 className="mt-[15px] text-3xl font-bold">{serviceData?.title}</h1>
        <p className="my-[15px] flex-1">{serviceData?.description}</p>
        <div className="mt-auto">
          <CheckNowBtn link={serviceData.link} />
        </div>
      </div>
    </div>
  );
}
